@import '../core/mixins/scrollbar';

body {
  @include scrollbar(
    $track-color: hsl(var(--ig-gray-100)),
    $track-border-radius: 0.375rem,
    $thumb-color: hsl(var(--ig-gray-300)),
    $thumb-hover-color: hsl(var(--ig-gray-500)),
    $thumb-border-radius: 0.375rem,
    $scrollbar-width: 0.375rem
  );
}
