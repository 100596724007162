/* for color names */
/* @see https://www.color-name.com */

$lavender: #efebfd;
$platinum: #e0deea;
$metallic-silver: #a5a3ae;
$sunglow: #f8cc39;
$dark-coral: #d75a47;
$green-sheen: #70c697;
$electric-blue: #009bcf;
$imperial-blue: #001a98;
$plump-purple: #6349bd;
$dark-gunmetal: #1f1a34;
$raisin-black: #262626;
$pure-white: #ffffff;
$mint-cream: #f8fffc;
$baby-powder: #fcfcff;
$background-color: #fbfbfd;
