@mixin loading-background($start-color: hsl(var(--ig-gray-200)), $end-color: hsl(var(--ig-gray-300))) {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, $start-color 8%, $end-color 38%, $start-color 54%);
  background-size: 150% 100%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 150% 0;
  }

  100% {
    background-position: -150% 0;
  }
}
