@use '@infragistics/igniteui-angular/theming' as igx;

@forward '@infragistics/igniteui-angular/theming';

@for $level from 1 through 6 {
  h#{$level},
  .heading-#{$level} {
    @include igx.type-style($category: 'h#{$level}');
  }
}

@for $level from 1 through 2 {
  .subtitle-#{$level} {
    @include igx.type-style($category: 'subtitle-#{$level}');
  }
}

p,
div,
.body-1 {
  @include igx.type-style($category: 'body-1');
}

span,
.body-2 {
  @include igx.type-style($category: 'body-2');
}

button,
input {
  @include igx.type-style($category: 'button');
}

small,
.caption {
  @include igx.type-style($category: 'caption');
}

.overline {
  @include igx.type-style($category: 'overline');
}
