@use '@infragistics/igniteui-angular/theming' as igx;
@use '../palettes' as supy;

@forward '@infragistics/igniteui-angular/theming';

@import 'core/mixins/flex';

$row-odd-background: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: '500-contrast',
);
$row-even-background: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: '500-contrast',
);
$row-hover-background: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'gray',
  $variant: 100,
);
$row-hover-text-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: '50-contrast',
);
$row-selected-background: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'gray',
  $variant: 200,
);
$row-odd-text-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'surface',
  $variant: '500-contrast',
);
$row-even-text-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'surface',
  $variant: '500-contrast',
);
$row-selected-text-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: '50-contrast',
);
$row-selected-hover-text-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: '50-contrast',
);
$header-background: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'gray',
  $variant: 50,
);
$header-text-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: '300-contrast',
);

$supy-grid-theme: igx.grid-theme(
  $content-background: transparent,
  $row-odd-background: $row-odd-background,
  $row-even-background: $row-even-background,
  $row-hover-background: $row-hover-background,
  $row-hover-text-color: $row-hover-text-color,
  $row-selected-background: $row-selected-background,
  $row-odd-text-color: $row-odd-text-color,
  $row-even-text-color: $row-even-text-color,
  $row-selected-text-color: $row-selected-text-color,
  $row-selected-hover-text-color: $row-selected-hover-text-color,
  $header-background: $header-background,
  $header-border-style: none,
  $header-text-color: $header-text-color,
  $grid-shadow: none,
);

.supy-grid {
  @include igx.css-vars($theme: $supy-grid-theme);

  .igx-grid {
    &__tr {
      border-radius: 0.625rem;
      margin-block-start: 0.3rem;
    }

    &__td-text {
      font-size: inherit;
    }

    &-thead {
      height: 3rem;

      .igx-grid__tr {
        margin-block-start: 0;
      }

      &__wrapper {
        z-index: 1;
      }
    }

    &-th {
      &__title {
        @include flex-container($align-items: center);

        height: 100%;
      }
    }

    &__td,
    &-thead__title,
    &-th {
      font-size: 0.875rem;
    }

    &__outlet {
      .igx-overlay__wrapper {
        position: absolute;
      }
    }

    &__row-editing-outlet {
      .igx-overlay__content {
        background: hsla(var(--ig-gray-300), 0.74);
      }
    }
  }
}
