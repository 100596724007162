.igx-drop-down {
  &__inner {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  &__item {
    --size: 2rem;

    padding-inline: 0.75rem;
  }

  supy-combo-box .igx-overlay__content & {
    &__item {
      padding-block: 0.5rem !important;
      padding-inline: 1rem 1.5rem !important;
    }
  }
}
