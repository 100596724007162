@import 'intro.js/introjs.css';

.introjs-tooltip {
  background-color: var(--ig-primary-400-contrast);
  border-radius: 0.5rem;
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  min-height: 14.625rem;
  min-width: 15.625rem;

  .introjs-tooltip-title {
    color: hsl(var(--ig-primary-700));
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: -0.015625rem;
  }

  .introjs-tooltiptext {
    font-size: 0.875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.25rem;
    padding-block-start: 0.5rem;
  }

  .introjs-bullets {
    a {
      background-color: hsl(var(--ig-primary-200));

      &.active {
        background-color: hsl(var(--ig-primary-700));
      }
    }
  }

  .introjs-tooltipbuttons {
    @include flex-container($align-items: center, $justify-content: space-around);

    border: none;

    .introjs-button-wrapper {
      @include flex-container($align-items: center, $gap: 0.5rem);

      color: hsl(var(--ig-primary-700));

      span {
        font-size: 0.875rem;
        font-weight: 700;
      }
    }

    .introjs-button {
      background-color: var(--ig-primary-400-contrast);
      border: none;
      color: hsl(var(--ig-primary-700));
      outline: 0;
      padding: 0.25rem 1rem 0.125rem;
      text-shadow: none;

      &:hover,
      &:focus {
        background-color: hsl(var(--ig-gray-50));
      }

      &.introjs-donebutton {
        background-color: hsl(var(--ig-primary-700));
        color: $pure-white;
      }

      &.introjs-disabled {
        visibility: hidden;
      }
    }
  }
}
