@use 'sass:string';
@use '@fontsource/noto-sans/scss/mixins' as NotoSans;

// stylelint-disable-next-line
$small-font-size: 12px;
// stylelint-disable-next-line
$default-font-size: 14px;
// stylelint-disable-next-line
$large-font-size: 16px;
$default-font-family: 'Noto Sans';

@mixin supy-default-font($esbuild) {
  $directory: null !default;
  @if $esbuild {
    $directory: '@fontsource/noto-sans/files';
  }
  @include NotoSans.faces(
    $directory: $directory,
    $subsets: (
      latin,
      greek,
    ),
    $weights: (
      300,
      400,
      500,
      600,
    )
  );
}
