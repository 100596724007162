@mixin flex-container(
  $display: flex,
  $direction: null,
  $align-content: null,
  $align-items: null,
  $justify-content: null,
  $place-content: null,
  $wrap: null,
  $gap: null
) {
  @if ($place-content) {
    place-content: $place-content;
  }

  align-content: $align-content;
  align-items: $align-items;
  display: $display;
  flex-direction: $direction;
  flex-wrap: $wrap;
  gap: $gap;
  justify-content: $justify-content;

  @content;
}

@mixin flex-item($align: auto, $basis: auto, $grow: auto, $shrink: auto) {
  align-self: $align;
  flex-basis: $basis;
  flex-grow: $grow;
  flex-shrink: $shrink;

  @content;
}
