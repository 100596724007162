@use '@infragistics/igniteui-angular/theming' as igx;
@use '../palettes' as supy;

@forward '@infragistics/igniteui-angular/theming';
$empty-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: 50,
);
$tick-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: 500,
);
$disabled-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'gray',
  $variant: 100,
);
$fill-color: igx.color(
  $palette: supy.$default-color-palette,
  $color: 'primary',
  $variant: 50,
);

$supy-checkbox-theme: igx.checkbox-theme(
  $border-radius: 0.25rem,
  $empty-color: $empty-color,
  $tick-color: $tick-color,
  $disabled-color: $disabled-color,
  $fill-color: $fill-color,
  $fill-color-hover: $tick-color,
);

.igx-checkbox {
  @include igx.css-vars($supy-checkbox-theme);

  &__composite {
    background: var(--ig-primary-500-contrast) !important;

    &::after {
      background: var(--ig-primary-500-contrast) !important;
    }
  }

  &--disabled {
    .igx-checkbox {
      &__composite {
        border-color: hsl(var(--ig-gray-300));

        &-mark {
          stroke: hsl(var(--ig-gray-300));
        }
      }
    }
  }

  &--focused {
    .igx-checkbox {
      &__composite {
        border-color: hsl(var(--ig-primary-700));
      }
    }
  }

  &:not(.igx-checkbox--checked) {
    &:not(.igx-checkbox--intermediate) {
      &:hover {
        .igx-checkbox {
          &__composite {
            border-color: hsl(var(--ig-primary-700));
          }
        }
      }
    }
  }
}
