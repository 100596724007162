@import 'core/mixins/flex';

.splash-screen {
  @include flex-container($direction: column, $align-items: center, $justify-content: center, $gap: 1rem);

  height: 100vh;
  position: fixed;
  width: 100vw;

  &__content {
    @include flex-container($direction: column, $align-items: center, $justify-content: center, $gap: 1rem);

    height: 90vh;
    width: inherit;
  }

  &__logo {
    height: 3rem;
    width: 8rem;
  }

  &__loading {
    animation: spin 1.4s infinite linear;
    height: 2rem;
    stroke: hsl(var(--ig-primary-700));
    width: 2rem;
  }

  &__footer {
    @include flex-container($align-items: center, $justify-content: center, $gap: 0.25rem);

    animation: fadeIn 1s 10s forwards;
    height: 10vh;
    visibility: hidden;
    width: inherit;

    &-text {
      color: hsl(var(--ig-gray-400));
      font-size: var(--ig-body-1-font-size);
    }

    &-link {
      color: hsl(var(--ig-primary-700));
      font-weight: 600;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  to {
    visibility: visible;
  }
}
