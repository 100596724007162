@use 'sass:map';
@use 'sass:meta';
@use '../../base' as *;
@use '../../themes/schemas' as *;
@use 'igniteui-theming/sass/animations/easings' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @param {Map} $schema [$light-material-schema] - The schema used as basis for styling the component.
/// @param {Map} $elevations [null] - The elevations (shadows) map to be used.
/// @param {Color} $idle-text-color [null] - The input text color in the idle state.
/// @param {Color} $filled-text-color [null] - The input text color in the filled state.
/// @param {Color} $filled-text-hover-color [null] - The input text color in the filled state on hover.
/// @param {Color} $focused-text-color [null] - The input text color in the focused state.
/// @param {Color} $disabled-text-color [null] - The input text color in the disabled state.
/// @param {Color} $helper-text-color [null] - The helper text color.
/// @param {Color} $input-prefix-color [null] - The input prefix color in the idle state.
/// @param {Color} $input-prefix-background [null] - The background color of an input prefix in the idle state.
/// @param {Color} $input-prefix-color--filled [null] - The input prefix color in the filled state.
/// @param {Color} $input-prefix-background--filled] - The background color of an input prefix in the filled state.
/// @param {Color} $input-prefix-color--focused [null] - The input prefix color in the focused state.
/// @param {Color} $input-prefix-background--focused [null] - The background color of an input prefix in the focused state.
/// @param {Color} $input-suffix-color [null] - The input suffix color in the idle state.
/// @param {Color} $input-suffix-background [null] - The background color of an input suffix in the idle state.
/// @param {Color} $input-suffix-color--filled [null] - The input suffix color in the filled state.
/// @param {Color} $input-suffix-background-filled [null] - The background color of an input suffix in the filled state.
/// @param {Color} $input-suffix-color--focused [null] - The input suffix color in the focused state.
/// @param {Color} $input-suffix-background--focused [null] - The background color of an input suffix in the focused state.
/// @param {Color} $idle-secondary-color [null] - The label color in the idle state.
/// @param {Color} $focused-secondary-color [null] - The label color in the focused state.
/// @param {Color} $idle-bottom-line-color [null] - The bottom line and border colors in the idle state.
/// @param {Color} $hover-bottom-line-color [null] - The bottom line and border colors in the hover state.
/// @param {Color} $focused-bottom-line-color [null] - The bottom line and border colors in the focused state.
/// @param {Color} $interim-bottom-line-color [null] - The bottom line and border colors during the to-focused transition.
/// @param {Color} $disabled-bottom-line-color [null] - The bottom line and border colors in the disabled state.
/// @param {Color} $border-color [null] - The border color for input groups of type border and fluent.
/// @param {Color} $hover-border-color [null] - The hover input border for input groups of type border and fluent.
/// @param {Color} $focused-border-color [null] - The focused input border color for input groups of type border and fluent.
/// @param {Color} $disabled-border-color [null] - The disabled border color for input groups of type border and fluent.
/// @param {Color} $box-background [null] - The background color of an input group of type box.
/// @param {Color} $box-background-hover [null] - The background color on hover of an input group of type box.
/// @param {Color} $box-background-focus [null] - The background color on focus of an input group of type box.
/// @param {Color} $box-disabled-background [null] - The background color of an input group of type box in the disabled state.
/// @param {Color} $border-background [null] - The background color of an input group of type border.
/// @param {Color} $border-disabled-background [null] - The background color of an input group of type border in the disabled state.
/// @param {Color} $search-background [null] - The background color of an input group of type search.
/// @param {Color} $search-disabled-background [null] - The background color of an input group of type search in the disabled state.
/// @param {box-shadow} $search-resting-shadow [null] - The shadow color of an input group of type search in its resting state.
/// @param {box-shadow} $search-hover-shadow [null] - The shadow color of an input group of type search in its hover state.
/// @param {box-shadow} $search-disabled-shadow [null] - The shadow color of an input group of type search in its disabled state.
/// @param {Color} $success-secondary-color [null] - The success color used in the valid state.
/// @param {Color} $warning-secondary-color [null] - The warning color used in the warning state.
/// @param {Color} $error-secondary-color [null] - The error color used in the error state.
/// @param {List} $box-border-radius [null] - The border radius used for box input.
/// @param {List} $border-border-radius [null] - The border radius used for border input.
/// @param {List} $search-border-radius [null] - The border radius used for search input.
/// @param {Color} placeholder-color [null] - The placeholder color of an input group.
/// @param {Color} hover-placeholder-color [null] - The placeholder color of an input group on hover.
/// @param {Color} disabled-placeholder-color [null] - The disabled placeholder color of an input group.
/// @example scss Change the focused border and label colors
///   $my-input-group-theme: input-group-theme($focused-secondary-color: pink, $focused-bottom-line-color: pink);
///   // Pass the theme to the css-vars() mixin
///   @include css-vars($my-input-group-theme);
@function input-group-theme(
    $schema: $light-material-schema,
    $elevations: null,

    $box-background: null,
    $box-background-hover: null,
    $box-background-focus: null,
    $box-disabled-background: null,
    $box-border-radius: null,

    $idle-text-color: null,
    $filled-text-color: null,
    $filled-text-hover-color: null,
    $focused-text-color: null,
    $helper-text-color: null,
    $disabled-text-color: null,

    $idle-secondary-color: null,
    $focused-secondary-color: null,

    $idle-bottom-line-color: null,
    $hover-bottom-line-color: null,
    $focused-bottom-line-color: null,
    $interim-bottom-line-color: null,
    $disabled-bottom-line-color: null,

    $border-color: null,
    $hover-border-color: null,
    $focused-border-color: null,
    $disabled-border-color: null,

    $border-border-radius: null,
    $border-background: null,
    $border-disabled-background: null,

    $search-background: null,
    $search-disabled-background: null,
    $search-border-radius: null,
    $search-resting-shadow: null,
    $search-hover-shadow: null,
    $search-disabled-shadow: null,

    $success-secondary-color: null,
    $warning-secondary-color: null,
    $error-secondary-color: null,
    $success-shadow-color: null,
    $error-shadow-color: null,

    $placeholder-color: null,
    $hover-placeholder-color: null,
    $disabled-placeholder-color: null,

    $input-prefix-color: null,
    $input-prefix-background: null,
    $input-prefix-color--filled: null,
    $input-prefix-background--filled: null,
    $input-prefix-color--focused: null,
    $input-prefix-background--focused: null,

    $input-suffix-color: null,
    $input-suffix-background: null,
    $input-suffix-color--filled: null,
    $input-suffix-background--filled: null,
    $input-suffix-color--focused: null,
    $input-suffix-background--focused: null,
    $size: null,
) {
    $name: 'igx-input-group';
    $selector: 'igx-input-group, igx-date-range-start, igx-date-range-end';
    $input-group-schema: ();

    @if map.has-key($schema, 'input-group') {
        $input-group-schema: map.get($schema, 'input-group');
    } @else {
        $input-group-schema: $schema;
    }

    $theme: digest-schema($input-group-schema);
    $search-resting-elevation: map.get($input-group-schema, 'search-resting-elevation');
    $search-hover-elevation: map.get($input-group-schema, 'search-hover-elevation');
    $search-disabled-elevation: map.get($input-group-schema, 'search-disabled-elevation');

    @if not($placeholder-color) and $box-background {
        $placeholder-color: text-contrast($box-background);
    }

    @if not($placeholder-color) and $box-background-hover {
        $placeholder-color: text-contrast($box-background-hover);
    }

    @if not($placeholder-color) and $box-background-focus {
        $placeholder-color: text-contrast($box-background-focus);
    }

    @if not($placeholder-color) and $search-background {
        $placeholder-color: text-contrast($search-background);
    }

    @if not($search-resting-shadow) {
        $search-resting-shadow: elevation($search-resting-elevation);
    }

    @if not($search-hover-shadow) {
        $search-hover-shadow: elevation($search-hover-elevation);
    }

    @if not($search-disabled-shadow) {
        $search-disabled-shadow: elevation($search-disabled-elevation);
    }

    @if not($input-prefix-background--filled) and $input-prefix-background {
        $input-prefix-background--filled: $input-prefix-background;
    }

    @if not($input-prefix-background--focused) and $input-prefix-background {
        $input-prefix-background--focused: $input-prefix-background;
    }

    @if not($input-prefix-color) and $input-prefix-background {
        $input-prefix-color: text-contrast($input-prefix-background);
    }

    @if not($input-prefix-color--filled) and $input-prefix-background--filled {
        $input-prefix-color--filled: text-contrast($input-prefix-background--filled);
    }

    @if not($input-prefix-color--focused) and $input-prefix-background--focused {
        $input-prefix-color--focused: text-contrast($input-prefix-background--focused);
    }

    @if not($input-suffix-color) and $input-suffix-background {
        $input-suffix-color: text-contrast($input-suffix-background);
    }

    @if not($input-suffix-color--filled) and $input-suffix-background--filled {
        $input-suffix-color--filled: text-contrast($input-suffix-background--filled);
    }

    @if not($input-suffix-color--focused) and $input-suffix-background--focused {
        $input-suffix-color--focused: text-contrast($input-suffix-background--focused);
    }

    @if not($box-border-radius) {
        $box-border-radius: map.get($theme, 'box-border-radius');
    }

    @if not($border-border-radius) {
        $border-border-radius: map.get($theme, 'border-border-radius');
    }

    @if not($search-border-radius) {
        $search-border-radius: map.get($theme, 'search-border-radius');
    }

    @return extend($theme, (
        name: $name,
        selector: $selector,
        idle-text-color: $idle-text-color,
        filled-text-color: $filled-text-color,
        filled-text-hover-color: $filled-text-hover-color,
        focused-text-color: $focused-text-color,
        disabled-text-color: $disabled-text-color,
        helper-text-color: $helper-text-color,
        box-border-radius: $box-border-radius,
        border-border-radius: $border-border-radius,
        search-border-radius: $search-border-radius,
        idle-secondary-color: $idle-secondary-color,
        focused-secondary-color: $focused-secondary-color,
        idle-bottom-line-color: $idle-bottom-line-color,
        hover-bottom-line-color: $hover-bottom-line-color,
        focused-bottom-line-color: $focused-bottom-line-color,
        interim-bottom-line-color: $interim-bottom-line-color,
        disabled-bottom-line-color: $disabled-bottom-line-color,
        border-color: $border-color,
        hover-border-color: $hover-border-color,
        focused-border-color: $focused-border-color,
        disabled-border-color: $disabled-border-color,
        box-background: $box-background,
        box-background-hover: $box-background-hover,
        box-background-focus: $box-background-focus,
        box-disabled-background: $box-disabled-background,
        border-background: $border-background,
        border-disabled-background: $border-disabled-background,
        search-background: $search-background,
        search-disabled-background: $search-disabled-background,
        search-resting-shadow: $search-resting-shadow,
        search-hover-shadow: $search-hover-shadow,
        search-disabled-shadow: $search-disabled-shadow,
        success-secondary-color: $success-secondary-color,
        warning-secondary-color: $warning-secondary-color,
        error-secondary-color: $error-secondary-color,
        success-shadow-color: $success-shadow-color,
        error-shadow-color: $error-shadow-color,
        placeholder-color: $placeholder-color,
        hover-placeholder-color: $hover-placeholder-color,
        disabled-placeholder-color: $disabled-placeholder-color,
        input-prefix-color: $input-prefix-color,
        input-prefix-background: $input-prefix-background,
        input-prefix-color--filled: $input-prefix-color--filled,
        input-prefix-background--filled: $input-prefix-background--filled,
        input-prefix-color--focused: $input-prefix-color--focused,
        input-prefix-background--focused: $input-prefix-background--focused,
        input-suffix-color: $input-suffix-color,
        input-suffix-background: $input-suffix-background,
        input-suffix-color--filled: $input-suffix-color--filled,
        input-suffix-background--filled: $input-suffix-background--filled,
        input-suffix-color--focused: $input-suffix-color--focused,
        input-suffix-background--focused: $input-suffix-background--focused,
        variant: map.get($schema, '_meta', 'theme'),
        size: $size,
    ));
}

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The theme used to style the component.
@mixin input-group($theme) {
    // The --variant CSS produced by css-vars is needed also
    // when dynamically switching between the input `type` attribute.
    @include css-vars($theme);

    $variant: map.get($theme, 'variant');
    $transition-timing: .25s $out-cubic;
    $material-theme: $variant == 'material';
    $indigo-theme: $variant == 'indigo-design';
    $fluent-theme: $variant == 'fluent';
    $bootstrap-theme: $variant == 'bootstrap';
    $NOT-material-theme: $variant != 'material';
    $NOT-indigo-theme: $variant != 'indigo-design';
    $NOT-fluent-theme: $variant != 'fluent';
    $NOT-bootstrap-theme: $variant != 'bootstrap';

    $required-symbol: '*';
    $required-symbol-margin: rem(2px);

    // This creates an inverse relationship
    // between the value of the base scale size and
    // the sizing of all inner elements.
    // i.e. the bigger the scale size,
    // the smaller the padding of the inner items.
    $base-scale-size: (
        'comfortable': 16px,
        'cosy': 19px,
        'compact': 22px
    );

    $bootstrap-inline-padding: (
        'comfortable': rem(16px),
        'cosy': rem(12px),
        'compact': rem(8px)
    );

    $bootstrap-block-padding: (
        'comfortable': rem(8px),
        'cosy': rem(6px),
        'compact': rem(4px)
    );

    $bootstrap-font-size: (
        'comfortable': rem(20px),
        'cosy': rem(16px),
        'compact': rem(14px),
    );

    $input-top-padding: rem(20px);
    $input-bottom-padding: rem(6px);

    $bundle-padding-top: (
        'comfortable': rem(18px, map.get($base-scale-size, 'comfortable')),
        'cosy': rem(18px, map.get($base-scale-size, 'cosy')),
        'compact': rem(18px, map.get($base-scale-size, 'compact')),
    );

    $hint-spacing-block: map.get((
        material: rem(5px),
        fluent: rem(5px),
        bootstrap: rem(4px),
        indigo-design: rem(4px),
    ), $variant);

    $hint-spacing-inline: map.get((
        material: rem(16px),
        fluent: 0,
        bootstrap: 0,
        indigo-design: 0,
    ), $variant);

    // Base Start
    %form-group-prefix--upload {
        padding: 0;
    }

    %form-group-prefix {
        color: var-get($theme, 'input-prefix-color');
        background: var-get($theme, 'input-prefix-background');
        grid-area: 1 / 1 / auto / auto;
    }

    %form-group-suffix {
        color: var-get($theme, 'input-suffix-color');
        background: var-get($theme, 'input-suffix-background');
        grid-area: 1 / 3 / auto / auto;
    }

    %form-group-prefix,
    %form-group-suffix {
        position: relative;
        display: inline-flex;
        width: max-content;
        align-items: center;

        @if $indigo-theme {
            height: calc(100% - #{rem(1px)}) !important;
        } @else {
            height: 100% !important;
        }
        transition: color $transition-timing, background $transition-timing;

        @if $material-theme {
            padding-inline: pad-inline(rem(12px), rem(14px), rem(16px));
        } @else if $indigo-theme {
            padding-inline: pad-inline(rem(2px), rem(4px), rem(6px));
        } @else {
            padding-inline: pad-inline(rem(8px), rem(12px), rem(16px));
        }
    }

    igx-input-group,
    igx-date-range-start,
    igx-date-range-end {
        --theme: #{$variant};
    }

    %form-group-display--box {
        %form-group-border {
            margin-bottom: 0;
        }
    }

    %form-group-display {
        @include sizable();
        --component-size: var(--ig-size, #{var-get($theme, 'default-size')});
        --input-size: var(--component-size);
        --input-icon: #{sizable(rem(14px), rem(16px), rem(18px))};

        position: relative;
        display: block;
        color: var-get($theme, 'idle-text-color');

        igx-prefix,
        [igxPrefix] {
            @extend %form-group-prefix;
            outline-style: none;
        }

        igx-suffix,
        [igxSuffix] {
            @extend %form-group-suffix;
            outline-style: none;
        }

        input,
        textarea,
        span {
            font: inherit;
            margin: 0;
        }

        input[type='file'] {
            @include hide-default();
        }

        textarea {
            overflow: auto;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }

        // Don't show the number spinner
        input[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
            height: auto;
        }

        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-decoration {
            -webkit-appearance: none;
        }

        igx-icon,
        igx-icon[igxPrefix],
        igx-icon[igxSuffix] {
            --component-size: var(--input-size);

            @if $variant == 'indigo-design' {
                --size: var(--input-icon) !important;
            }
        }
    }

    %form-group-display--no-margin {
        margin-block-start: 0;
    }

    %form-group-display--filled {
        color: var-get($theme, 'input-prefix-color--filled');

        igx-prefix,
        [igxPrefix] {
            color: var-get($theme, 'input-prefix-color--filled');
            background: var-get($theme, 'input-prefix-background--filled');
        }

        igx-suffix,
        [igxSuffix] {
            color: var-get($theme, 'input-suffix-color--filled');
            background: var-get($theme, 'input-suffix-background--filled');
        }
    }

    %form-group-display--focused {
        color: var-get($theme, 'input-prefix-color--focused');

        igx-prefix,
        [igxPrefix] {
            color: var-get($theme, 'input-prefix-color--focused');
            background: var-get($theme, 'input-prefix-background--focused');
        }

        igx-suffix,
        [igxSuffix] {
            color: var-get($theme, 'input-suffix-color--focused');
            background: var-get($theme, 'input-suffix-background--focused');
        }
    }

    %form-group-display--disabled {
        user-select: none;
        color: var-get($theme, 'disabled-text-color') !important;

        igx-prefix,
        [igxPrefix] {
            @extend %form-group-prefix--disabled;
        }

        igx-suffix,
        [igxSuffix] {
            @extend %form-group-suffix--disabled;
        }
    }

    %form-group-box-wrapper {
        @if $variant != 'bootstrap' {
            border-radius: var-get($theme, 'box-border-radius');
        }

        @if $variant == 'material' {
            border-end-start-radius: 0;
            border-end-end-radius: 0;
        }
        overflow: hidden;
    }

    %form-group-bundle {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-area: 1 / 2 / span 1 / span 2;
        height: var-get($theme, 'size');
        position: relative;
        max-width: 100%;
        font-size: rem(16px);

        &::after {
            content: '';
            position: absolute;
            bottom: 0%;
            width: 100%;
            border-block-end: rem(1px) solid var-get($theme, 'idle-bottom-line-color');
            transition: all $transition-timing
        }

        %form-group-bundle-start {
            border: {
                start: {
                    start-radius: calc(var-get($theme, 'box-border-radius') - rem(1px));
                };
            }
        }

        %form-group-bundle-end {
            border: {
                start: {
                    end-radius: calc(var-get($theme, 'box-border-radius') - rem(1px));
                }
            }
        }
    }

    // We need to target the bundle :after here with classes since we have cases that
    // The theme is for example indigo but the input stays fluent, that's the case in the grid.
    .igx-input-group--fluent,
    .igx-input-group--bootstrap {
        %form-group-bundle {
            &::after {
                display: none;
            }
        }
    }

    %form-group-bundle--hover {
        cursor: pointer;

        &::after {
            border-block-end-width: rem(1px);
            border-block-end-color: var-get($theme, 'hover-bottom-line-color');
        }
    }

    %form-group-bundle--focus {
        &::after {
            @if $variant != 'indigo-design' {
                border-block-end-width: rem(2px);
            }
            border-block-end-color: var-get($theme, 'focused-bottom-line-color');
        }

        @if $variant == 'indigo-design' {
            caret-color: var-get($theme, 'focused-bottom-line-color');
        }
    }

    %form-group-bundle--success {
        &::after {
            border-block-end-color: var-get($theme, 'success-secondary-color');
        }
        caret-color: initial;
    }

    %form-group-bundle--error {
        &::after {
            border-block-end-color: var-get($theme, 'error-secondary-color');
        }
        caret-color: initial;
    }

    %form-group-bundle--disabled {
        cursor: default;

        &::after {
            border-block-end-color: var-get($theme, 'disabled-bottom-line-color');
            border-block-end-style: dashed;
        }
    }

    %form-group-bundle-start {
        grid-area: 1 / 1;
    }

    %form-group-bundle-main {
        grid-area: 1 / 2 / span 1 / span 2;
        flex-grow: 1;
        position: relative;
        max-width: inherit;
    }

    %form-group-bundle--box {
        // padding 0 is needed here because of the search variant
        padding: 0 !important;

        background: var-get($theme, 'box-background');

        &:hover {
            background: var-get($theme, 'box-background-hover');
        }
    }

    %form-group-bundle--box-focus {
        background: var-get($theme, 'box-background-focus');
    }

    %form-group-bundle--box-disabled {
        background-color: var-get($theme, 'box-disabled-background');
    }

    @if $material-theme {
        %form-group-bundle-main,
        %igx-input-group__notch {
            padding-inline: rem(4px);
        }
    }

    %igx-input-group__notch {
        display: flex;
        align-items: center;
        width: auto;
        min-width: 0;
        height: 100%;
        position: relative;

        grid-area: 1 / 2;
    }

    %igx-input-group__notch--border {
        padding: 0 rem(4px);
    }

    %igx-input-group__filler {
        grid-area: 1 / 3;
    }

    %form-group-bundle-end {
        grid-area: 1 / 4;
    }


    %form-group-bundle-start,
    %form-group-bundle-end {
        display: flex;
        align-items: center;
        min-width: 0;
        min-height: 100%;

        @if $variant == 'material' {
            overflow: hidden;
            min-width: pad(rem(10px), rem(12px), rem(14px));
        }
    }

    %form-group-bundle--border {
        grid-template-columns: auto auto 1fr auto;
        display: grid;
        align-items: initial;
        padding: 0;
        box-shadow: none;
        border-radius: var-get($theme, 'border-border-radius');
        background: var-get($theme, 'border-background');

        %form-group-bundle-start {
            width: auto;
            flex-shrink: 0;

            border: {
                color: var-get($theme, 'border-color');
                style: solid;

                inline: {
                    start-width: rem(1px);
                    end-width: 0;
                };

                block: {
                    start-width: rem(1px);
                    end-width: rem(1px);
                };

                start: {
                    start-radius: var-get($theme, 'border-border-radius');
                };

                end: {
                    start-radius: var-get($theme, 'border-border-radius');
                };
            }
        }

        %igx-input-group__filler {
            border: {
                width: rem(1px);
                style: solid;
                color: var-get($theme, 'border-color');
                left: none;
                right: none;
            }
        }

        %igx-input-group__notch {
            border-block-start: rem(1px) solid var-get($theme, 'border-color');
            border-block-end: rem(1px) solid var-get($theme, 'border-color');
            overflow: visible;

            %form-group-label {
                position: relative;
            }

            &:empty {
                display: none;
            }
        }

        %form-group-bundle-end {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            height: 100%;
            grid-area: 1 / 4;

            border: {
                color: var-get($theme, 'border-color');
                style: solid;

                inline: {
                    start-width: 0;
                    end-width: rem(1px);
                };

                block: {
                    start-width: rem(1px);
                    end-width: rem(1px);
                };

                start: {
                    end-radius: var-get($theme, 'border-border-radius');
                };

                end: {
                    end-radius: var-get($theme, 'border-border-radius');
                };
            }
        }

        %form-group-prefix,
        %form-group-suffix {
            height: 100%;
        }

        &::after {
            display: none;
        }
    }

    %form-group-bundle-border--disabled {
        background: var-get($theme, 'border-disabled-background');
    }

    %form-group-input--border {
        width: 100%;
        height: 100% !important;
        padding-block: 0;
        border: none;
        outline-style: none;
        z-index: 1;
    }

    /* stylelint-disable */
    %igx-input-group__notch--search,
    %form-group-bundle-main--search {
        @if $material-theme {
            padding-inline-start: pad-inline(rem(14px), rem(16px), rem(18px));
        }
    }

    %igx-input-group__notch--search,
    %form-group-bundle-main--search {
        @if $material-theme {
            padding-inline-end: pad-inline(rem(14px), rem(16px), rem(18px));
        }
    }
    /* stylelint-enable */

    %form-group-display--search {
        %igx-input-group__notch--search,
        %form-group-bundle-main--search {
            @if $variant != 'indigo-design' {
                padding-inline: rem(4px);
            }
        }
    }

    %form-group-bundle--search {
        background: var-get($theme, 'search-background');
        box-shadow: var-get($theme, 'search-resting-shadow');

        @if $variant != 'bootstrap' {
            border-radius: var-get($theme, 'search-border-radius');
            overflow: hidden;
        }

        @if $variant != 'indigo-design' {
            &::after {
                display: none;
            }
        }
    }

    %form-group-bundle-search--hover {
        box-shadow: var-get($theme, 'search-hover-shadow');
        border-color: var-get($theme, 'hover-border-color');
    }

    %form-group-bundle-search--focus {
        box-shadow: var-get($theme, 'search-hover-shadow');
        border-color: var-get($theme, 'hover-border-color');
    }

    %form-group-bundle-search--error {
        box-shadow: var-get($theme, 'search-hover-shadow');
        border-color: var-get($theme, 'search-hover-shadow');
    }

    %form-group-bundle-search--success {
        box-shadow: var-get($theme, 'search-hover-shadow');
        border-color: var-get($theme, 'search-hover-shadow');
    }

    %form-group-bundle-search--disabled {
        background: var-get($theme, 'search-disabled-background');
        box-shadow: var-get($theme, 'search-disabled-shadow');
        border-color: var-get($theme, 'disabled-border-color');
    }

    %form-group-bundle-main--border {
        background: transparent;
        padding: 0 rem(4px);
        font-size: rem(16px);
    }

    %form-group-label {
        padding-inline-end: rem(4px);
        backface-visibility: hidden;
        will-change: transform;
        transform-origin: top left;
        transition: all $transition-timing;
        margin-inline-start: pad-inline(0, rem(-2px), rem(-4px));
    }

    %form-group-label--border {
        padding-inline-end: 0;
        display: inline-block;
        position: relative;
        background: transparent;
    }

    %form-group-label--border,
    %form-group-label--search {
        transform: translateY(0);
    }

    %form-group-label--search {
        + %form-group-input--search {
            transform: translateY(0);
        }
    }

    %form-group-label--float {
        --floating-label-position: -73%;
        transform: translateY(var(--floating-label-position));
        font-size: rem(12px);
    }

    %form-group-label--focused-border,
    %form-group-label--filled-border,
    %form-group-label--placeholder-border,
    %form-group-label--file-border {
        %igx-input-group__notch {
            border-block-start-color: transparent !important;
        }
    }

    %form-group-label--focused-border {
        %form-group-bundle-start {
            border-inline-start-width: rem(2px);
            border-block-start-width: rem(2px);
            border-block-end-width: rem(2px);
            border-inline-start-color: var-get($theme, 'focused-border-color');
            border-block-start-color: var-get($theme, 'focused-border-color');
            border-block-end-color: var-get($theme, 'focused-border-color');
        }

        %form-group-bundle-end {
            border-inline-end-width: rem(2px);
            border-block-start-width: rem(2px);
            border-block-end-width: rem(2px);
            border-inline-end-color: var-get($theme, 'focused-border-color');
            border-block-start-color: var-get($theme, 'focused-border-color');
            border-block-end-color: var-get($theme, 'focused-border-color');
        }

        %igx-input-group__filler,
        %igx-input-group__notch {
            border-block-width: rem(2px);
        }

        %igx-input-group__filler {
            border-block-color: var-get($theme, 'focused-border-color');
        }

        %igx-input-group__notch {
            border-block-end-color: var-get($theme, 'focused-border-color');
        }

        %form-group-prefix {
            &:first-child {
                margin-inline-start: rem(-1px);
            }
        }

        %form-group-suffix {
            &:last-child {
                margin-inline-end: rem(-1px);
            }
        }
    }

    %form-group-label--float-border {
        --label-position: #{sizable(18px, 22px, 26px)};
        transform: translateY(calc(var(--label-position) * -1));
        font-size: rem(12px);
        margin-top: 0;
        overflow: hidden;
        will-change: font-size, color, transform;
    }

    @if $variant == 'material' {
        %textarea-group:not(%suffixed) {
            %form-group-bundle-main {
                grid-area: 1 / 2 / span 1 / span 3;
                padding-inline-end: 0;
            }

            textarea {
                padding-inline-end: rem(4px);
                width: calc(100% - #{rem(1px)});
            }
        }

        %textarea-group--outlined:not(%suffixed) {
            textarea {
                width: calc(100% - #{rem(2px)});
            }
        }
    }

    %form-group-textarea-group-bundle {
        // 3 lines * 22px + 8px bottom padding + 8px top padding
        --textarea-size: #{sizable(
            rem(82px, map.get($base-scale-size, 'compact')),
            rem(82px, map.get($base-scale-size, 'cosy')),
            rem(82px, map.get($base-scale-size, 'comfortable'))
        )};
        min-height: var(--textarea-size) !important;
        height: auto !important;

        %form-group-label {
            position: absolute;
        }
    }

    @if $material-theme {
        %form-group-textarea-label {
            top: calc($input-top-padding - #{rem(1px)});
            margin-block-end: auto;
        }

        %textarea-group--outlined {
            %form-group-textarea-label {
                top: calc($input-top-padding - #{rem(3px)});
            }
        }

        %textarea-group--box {
            %form-group-textarea-label {
                top: calc($input-top-padding - #{rem(2px)});
            }
        }

        %textarea-group-label--focused {
            transform: translateY(0);
            top: calc(#{$input-top-padding} / 4);
        }

        %textarea-group-label--filled--border,
        %textarea-group-label--focused--border {
            top: 0;
            transform: translateY(-50%);
            margin-block-end: auto !important;
        }

        %textarea-group-notch--focused {
            border-block-start-width: rem(2px);
        }
    }

    %form-group-label--focus {
        color: var-get($theme, 'focused-secondary-color');
    }

    %form-group-label--success {
        color: var-get($theme, 'success-secondary-color');
    }

    %form-group-label--error {
        color: var-get($theme, 'error-secondary-color');
    }

    %form-group-label--required {
        &::after {
            content: '#{$required-symbol}';
            font-size: inherit;
            vertical-align: top;
            margin-inline-start: $required-symbol-margin; /* rem(2px) base is 16px */
            display: inline-block;
        }
    }

    %form-group-label--disabled {
        color: var-get($theme, 'disabled-text-color') !important;
    }

    %form-group-input {
        position: relative;
        display: block;
        border: none;
        padding-block-start: $input-top-padding;
        padding-block-end: $input-bottom-padding;
        padding-inline: 0;
        height: calc(var-get($theme, 'size') - 2px);
        width: 100%;
        min-width: 0;
        background: transparent;
        color: var-get($theme, 'filled-text-color');
        outline-style: none;
        box-shadow: none;
        overflow: hidden;
        text-overflow: ellipsis;

        &:not([type='date']) {
            line-height: 0 !important; /* Reset typography */
        }

        &::-webkit-input-placeholder {
            line-height: normal;
        }

        &::placeholder {
            color: var-get($theme, 'placeholder-color');
            opacity: 1;
            line-height: normal; /* Fix placeholder position in Safari */
        }

        @if $variant == 'indigo-design' {
            height: calc(var-get($theme, 'size') - 1px);

            &::placeholder {
                font-style: italic;
            }
        }
    }

    %igx-input-group__notch:empty + %form-group-bundle-main {
        %form-group-input {
            padding-block: 0;
        }
    }

    %form-group-file-input {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        padding-block-start: $input-top-padding;
        padding-block-end: $input-bottom-padding;
        height: calc(var-get($theme, 'size') - 2px);
        color: var-get($theme, 'filled-text-color');

        span {
            @include ellipsis();
            position: relative;
            display: inline-block;
            width: inherit;
            max-width: inherit;
            top: 50%;
            transform: translateY(-50%);
        }

        @if $variant == 'indigo-design' {
            font-size: rem(12px);
            line-height: rem(16px);
        }
    }

    %form-group-clear-icon {
        &:focus {
            background-color: color($color: 'primary', $variant: 500);
            color: contrast-color($color: 'primary', $variant: 600);

            @if $variant == 'material' {
                background-color: transparent;
                color: color($color: 'secondary', $variant: 500);
            }

            @if $variant == 'bootstrap' {
                color: contrast-color($color: 'primary', $variant: 600);
            }
        }
    }

    %chrome-autofill-fix {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-transition-delay: 99999s;
            transition-delay: 99999s;
        }
    }

    %edge-clear-icon-fix {
        &::-ms-clear {
            display: none;
        }
    }

    %form-group-file-input-indigo {
        padding-block: 0;
    }

    %form-group-input--hover {
        cursor: pointer;
        color: var-get($theme, 'filled-text-hover-color');

        &::placeholder {
            color: var-get($theme, 'hover-placeholder-color');
        }
    }

    %form-group-input--focus {
        cursor: text;
        color: var-get($theme, 'focused-text-color');

        &::placeholder {
            color: var-get($theme, 'hover-placeholder-color');
        }
    }

    %form-group-input--disabled {
        cursor: default;

        color: var-get($theme, 'disabled-text-color') !important;

        &::placeholder {
            color: var-get($theme, 'disabled-placeholder-color');
        }
    }

    %form-group-textarea {
        --textarea-size: #{sizable(
            rem(82px, map.get($base-scale-size, 'compact')),
            rem(82px, map.get($base-scale-size, 'cosy')),
            rem(82px, map.get($base-scale-size, 'comfortable'))
        )};

        min-height: var(--textarea-size);
        height: auto;
        resize: vertical;
        overflow: hidden;

        @if $material-theme {
            padding: 0;
            inset-block-start: rem(-3px);
        }

        // resets typography styles
        line-height: normal !important;

        z-index: 1;

        &:not([type='*']) {
            line-height: normal !important; /* resets typography styles */
        }
    }

    %form-group-textarea-group-bundle-main {
        overflow: hidden;

        @if $material-theme or $indigo-theme {
            height: calc(100% - #{rem(2px)});
            top: rem(1px);
        }

        @if $material-theme {
            padding-block-start: $input-top-padding;
        }
    }

    %form-group-textarea--disabled {
        color: var-get($theme, 'disabled-text-color') !important;

        &::placeholder {
            color: var-get($theme, 'disabled-placeholder-color');
        }
    }

    @if $material-theme {
        %form-group-line {
            position: absolute;
            width: 100%;
            inset-inline-end: 0;
            height: rem(2px);
            align-self: end;
            transform: scaleX(0);
            transform-origin: center;
            background: var-get($theme, 'focused-secondary-color');
            z-index: 1;
        }
    }

    %form-group-line--success {
        background: var-get($theme, 'success-secondary-color');
    }

    %form-group-border--success {
        %form-group-bundle-start {
            border-inline-start-color: var-get($theme, 'success-secondary-color');
            border-block-start-color: var-get($theme, 'success-secondary-color');
            border-block-end-color: var-get($theme, 'success-secondary-color');
        }

        %form-group-bundle-end {
            border-inline-end-color: var-get($theme, 'success-secondary-color');
            border-block-start-color: var-get($theme, 'success-secondary-color');
            border-block-end-color: var-get($theme, 'success-secondary-color');
        }

        %igx-input-group__notch,
        %igx-input-group__filler {
            border-block-color: var-get($theme, 'success-secondary-color');
        }
    }

    %form-group-line--warning {
        background: var-get($theme, 'error-secondary-color');
    }

    %form-group-border--warning {
        %form-group-bundle-start {
            border-inline-start-color: var-get($theme, 'warning-secondary-color');
            border-block-start-color: var-get($theme, 'warning-secondary-color');
            border-block-end-color: var-get($theme, 'warning-secondary-color');
        }

        %form-group-bundle-end {
            border-inline-end-color: var-get($theme, 'warning-secondary-color');
            border-block-start-color: var-get($theme, 'warning-secondary-color');
            border-block-end-color: var-get($theme, 'warning-secondary-color');
        }

        %igx-input-group__notch,
        %igx-input-group__filler {
            border-block-color: var-get($theme, 'warning-secondary-color');
        }
    }

    %form-group-line--error {
        background: var-get($theme, 'error-secondary-color');
    }

    %form-group-border--error {
        %form-group-bundle-start {
            border-inline-start-color: var-get($theme, 'error-secondary-color');
            border-block-start-color: var-get($theme, 'error-secondary-color');
            border-block-end-color: var-get($theme, 'error-secondary-color');
        }

        %form-group-bundle-end {
            border-inline-end-color: var-get($theme, 'error-secondary-color');
            border-block-start-color: var-get($theme, 'error-secondary-color');
            border-block-end-color: var-get($theme, 'error-secondary-color');
        }

        %igx-input-group__notch,
        %igx-input-group__filler {
            border-block-color: var-get($theme, 'error-secondary-color');
        }
    }

    %form-group-border--disabled {
        %form-group-bundle-start {
            border-inline-start-color: var-get($theme, 'disabled-border-color');
            border-block-start-color: var-get($theme, 'disabled-border-color');
            border-block-end-color: var-get($theme, 'disabled-border-color');
        }

        %form-group-bundle-end {
            border-inline-end-color: var-get($theme, 'disabled-border-color');
            border-block-start-color: var-get($theme, 'disabled-border-color');
            border-block-end-color: var-get($theme, 'disabled-border-color');
        }

        %igx-input-group__notch,
        %igx-input-group__filler {
            border-block-color: var-get($theme, 'disabled-border-color');
        }
    }

    %form-group-line--focus {
        transform: scaleX(1);
        transition: transform $transition-timing;
    }

    // Hides the border for border type input
    %form-group-line--hidden {
        display: none;
    }

    %form-group-helper {
        color: var-get($theme, 'helper-text-color');
        position: relative;
        display: flex;
        padding-block-start: $hint-spacing-block;
        padding-inline-start: $hint-spacing-inline;

        justify-content: space-between;

        > * {
            margin-inline-end: rem(8px);

            &:last-child {
                margin-inline-end: 0;
            }
        }

        &:empty {
            @if $variant == 'fluent' {
                padding: 0;
            } @else {
                display: none;
            }
        }
    }

    @if $variant != 'indigo-design' {
        %form-group-helper--success {
            color: var-get($theme, 'success-secondary-color');
        }

        %form-group-helper--error {
            color: var-get($theme, 'error-secondary-color');
        }
    }

    %form-group-helper-item {
        display: flex;
        align-items: center;
        position: relative;
    }

    %form-group-helper-item--start {
        justify-content: flex-start;
    }

    %form-group-helper-item--end {
        justify-content: flex-end;
    }

    %form-group-helper-item--start,
    %form-group-helper-item--end {
        width: 100%;
    }

    %form-group-prefix--disabled,
    %form-group-suffix--disabled {
        color: var-get($theme, 'disabled-text-color');
        background: inherit;
        pointer-events: none;
    }

    %form-group-helper--disabled {
        color: var-get($theme, 'disabled-text-color');
    }

    // BASE END

    // ==============================================

    // INDIGO START
    @if $variant == 'indigo-design' {
        %form-group-display--search {
            igx-prefix,
            [igxPrefix],
            igx-suffix,
            [igxSuffix] {
                padding-inline: pad-inline(rem(6px), rem(8px), rem(10px));
            }
        }

        %form-group-display.igx-input-group--prefixed,
        %form-group-display--search.igx-input-group--prefixed {
            input,
            textarea {
                padding-inline-start: 0;
            }
        }

        %form-group-display.igx-input-group--suffixed,
        %form-group-display--search.igx-input-group--suffixed {
            input,
            textarea {
                padding-inline-end: 0;
            }
        }
    }

    %form-group-bundle--indigo {
        border-radius: var-get($theme, 'box-border-radius') var-get($theme, 'box-border-radius') 0 0;
        transition: background $transition-timing;
        padding-top: 0;

        &:hover,
        &:focus {
            background: var-get($theme, 'box-background-hover');
        }
    }

    %indigo-label--focused {
        color: var-get($theme, 'focused-secondary-color');
    }

    %form-group-input--indigo {
        padding-block: rem(6px);
        padding-inline: pad-inline(rem(2px), rem(4px), rem(6px));
    }

    @if $variant == 'indigo-design' {
        %form-group-input--search {
            padding-inline: pad-inline(rem(6px), rem(8px), rem(10px));
        }
    }

    %indigo--box-focused {
        background: var-get($theme, 'box-background-hover');
    }

    %form-group-bundle--indigo--disabled {
        background: transparent;

        &:hover,
        &:focus {
            background: transparent;
        }

        &::after {
            border-bottom-style: solid;
        }
    }

    %indigo-upload-button {
        [igxButton] {
            min-height: 0;
            height: calc(100% - #{rem(8px)});
        }
    }
    // INDIGO END

    // ==============================================

    // FLUENT START
    // Input
    %igx-input-group-fluent {
        igx-prefix,
        [igxPrefix] {
            @extend %form-group-prefix-fluent;
        }

        igx-suffix,
        [igxSuffix] {
            @extend %form-group-suffix-fluent;
        }

        igx-prefix,
        [igxPrefix],
        igx-suffix,
        [igxSuffix] {
            outline-style: none;
        }

        select {
            width: calc(100% + #{rem(8px)});
            margin-inline-start: rem(-8px) !important;
            cursor: pointer !important;
        }
    }

    %igx-input-group-fluent-search {
        display: flex;
        flex-direction: column;

        igx-prefix,
        [igxPrefix] {
            overflow: hidden;
        }
    }

    %igx-input-group-fluent-search--focused {
        igx-prefix,
        [igxPrefix] {
            display: none;
        }
    }

    // Bundle
    %form-group-bundle-required--fluent {
        &::before {
            content: '*';
            position: absolute;
            top: rem(-8px);
            inset-inline-start: calc(100% + #{rem(4px)});
            color: var-get($theme, 'error-secondary-color');
        }
    }

    %form-group-bundle-bootstrap--textarea,
    %form-group-bundle-fluent--textarea {
        display: flex;
    }

    %form-group-label-required--fluent {
        &::after {
            color: var-get($theme, 'error-secondary-color');
        }
    }

    %form-group-label-required--disabled--fluent {
        &::after {
            color: var-get($theme, 'disabled-text-color');
        }
    }

    %form-group-bundle--fluent {
        --min-width: #{sizable(rem(4px), rem(6px), rem(8px))};

        min-height: var-get($theme, 'size');
        padding: 0;
        border: rem(1px) solid var-get($theme, 'border-color');
        background: var-get($theme, 'border-background');
        align-items: stretch;
        overflow: hidden;

        @if $variant == 'fluent' {
            border-radius: var-get($theme, 'border-border-radius');
        }

        // Those focus styles are needed since when we focus the button inside
        // the file type input the class .igx-input-group--focused is not added but the input is focused.
        &:focus,
        &:focus-within {
            %form-group-bundle-start {
                margin-block-start: rem(-1px);
                margin-inline-start: rem(-1px);
            }

            %form-group-bundle-end {
                margin-block-start: rem(-1px);
                margin-inline-end: rem(-1px);
            }

            %form-group-bundle-start:empty + %form-group-bundle-main,
            %form-group-bundle-end:empty + %form-group-bundle-main {
                margin-inline-start: rem(-1px);
            }

            %form-group-bundle-main {
                margin-block-start: rem(-1px);
            }
        }
    }

    %form-group-bundle--fluent--hover {
        border-color: var-get($theme, 'hover-border-color');
    }

    %form-group-bundle--fluent--focus {
        border-color: var-get($theme, 'focused-border-color');
        border-width: rem(2px);
    }

    %form-group-bundle-error--fluent,
    %form-group-bundle-error--fluent--hover,
    %form-group-bundle-error--fluent--focus {
        border-color: var-get($theme, 'error-secondary-color');
    }

    %form-group-bundle-success--fluent,
    %form-group-bundle-success--fluent--hover,
    %form-group-bundle-success--fluent--focus {
        border-color: var-get($theme, 'success-secondary-color');
    }

    %form-group-bundle--fluent--hover-disabled,
    %form-group-bundle--fluent-disabled {
        border-color: var-get($theme, 'disabled-border-color');
        background: var-get($theme, 'border-disabled-background');
    }

    %form-group-bundle-main--fluent {
        align-self: center;
        cursor: default;
    }

    @if $fluent-theme {
        %form-group-bundle-start--fluent--focused {
            margin-block-start: rem(-1px);
            margin-inline-start: rem(-1px);
        }

        %form-group-bundle-end--fluent--focused {
            margin-block-start: rem(-1px);
            margin-inline-end: rem(-1px);
        }

        %form-group-bundle-start--fluent--focused:empty + %form-group-bundle-main--fluent--focused,
        %form-group-bundle-end--fluent--focused:empty + %form-group-bundle-main--fluent--focused {
            margin-inline-start: rem(-1px);
        }

        %form-group-bundle-main--fluent--focused {
            margin-block-start: rem(-1px);
        }
    }

    %form-group-bundle-textarea-start--fluent,
    %form-group-bundle-textarea-end--fluent {
        &:empty {
            display: none;
        }
    }

    // Native input
    %fluent-input {
        font-size: rem(14px);
        padding-block: 0;
        padding-inline: rem(8px);
        margin: 0;
        border: none;
    }

    %indigo-textarea {
        padding-block: rem(6px);
        padding-inline: pad-inline(rem(2px), rem(4px), rem(6px));
        inset-block-end: rem(2px);
    }

    %fluent-textarea {
        padding: rem(8px);
    }

    %fluent-input-disabled {
        color: var-get($theme, 'disabled-text-color');
    }

    // Label
    %fluent-label {
        display: block;
        font-size: rem(14px);
        font-weight: 600;
        position: static;
        transform: translateY(0);
        transform-origin: top left;
        margin-top: 0 !important;
        height: auto;
        margin-block-end: rem(4px);
        color: var-get($theme, 'idle-secondary-color');

        [dir='rtl'] & {
            transform-origin: top right;
        }

        @if $variant == 'indigo-design' {
            font-size: rem(11px);
            line-height: rem(15px);
            font-weight: 400;
        }
    }

    %fluent-label + %form-group-bundle-required--fluent {
        &::before {
            display: none;
        }
    }

    %fluent-label-success {
        color: var-get($theme, 'idle-text-color');
    }

    %fluent-label-error {
        color: var-get($theme, 'idle-text-color');
    }

    %fluent-label-disabled {
        color: var-get($theme, 'disabled-text-color') !important;
    }

    %fluent-label-filled {
        transform: translateY(0);
    }

    %fluent-label-focused {
        color: color(null, 'gray', 800);
        transform: translateY(0) scale(1);
    }

    %form-group-helper--textarea--fluent--focused {
        margin-block-start: rem(-1px);
    }

    %fluent-placeholder-label {
        transform: translateY(0) scale(1);
    }

    %form-group-prefix-fluent,
    %form-group-suffix-fluent {
        .ig-typography [igx-button],
        .ig-typography igx-button,
        .ig-typography button,
        button {
            border-radius: 0;
            height: 100%;
        }
    }

    .igx-input-group--fluent [igxPrefix],
    .igx-input-group--fluent igx-prefix {
        &:first-child {
            [igxButton]::after,
            button::after {
                border: {
                    start: {
                        start-radius: var-get($theme, 'border-border-radius');
                    };
                    end: {
                        start-radius: var-get($theme, 'border-border-radius');
                    };
                }
            }
        }
    }

    %form-group-prefix-fluent,
    %form-group-suffix-fluent,
    %form-group-prefix-fluent-search,
    %form-group-suffix-fluent-search {
        padding-inline: pad-inline(rem(8px), rem(12px), rem(16px));
    }

    %fluent-upload-button {
        padding: 0;
    }
    // FLUENT END

    // ==============================================

    // === BOOTSTRAP START === //
    // Input group host
    %form-group-display--bootstrap-prefixed {
        %bootstrap-file-input,
        %bootstrap-input {
            border: {
                start: {
                    start-radius: 0;
                };
                end: {
                    start-radius: 0;
                };
            }
        }
    }

    %form-group-display--bootstrap-suffixed {
        %bootstrap-file-input,
        %bootstrap-input {
            border: {
                start: {
                    end-radius: 0;
                };
                end: {
                    end-radius: 0;
                };
            }
        }
    }

    %form-group-display--disabled-bootstrap {
        background-image: none;

        igx-prefix,
        [igxPrefix] {
            @extend %form-group-prefix--disabled-bootstrap;
        }

        igx-suffix,
        [igxSuffix] {
            @extend %form-group-suffix--disabled-bootstrap;
        }

        %form-group-bundle-start--bootstrap,
        %form-group-bundle-end--bootstrap {
            border-color: var-get($theme, 'disabled-border-color');
        }
    }

    // Bundle
    %form-group-bundle--bootstrap {
        padding: 0;
        box-shadow: none;
        z-index: 0;
    }

    %form-group-bundle--bootstrap-focused,
    %form-group-bundle--bootstrap-hover {
        border: none;
        box-shadow: none;
    }

    %form-group-bundle-start--bootstrap,
    %form-group-bundle-end--bootstrap {
        flex: none;

        &:empty {
            display: none;
        }

        border-width: rem(1px);
        border-style: solid;
        border-color: var-get($theme, 'border-color');
        overflow: hidden;
    }

    %form-group-bundle-start--bootstrap {
        grid-area: 1 / 1;

        border: {
            inline: {
                start-width: rem(1px);
                end-width: 0;
            };
            block: {
                start-width: rem(1px);
                end-width: rem(1px);
            };
            start: {
                start-radius: var-get($theme, 'box-border-radius');
            };
            end: {
                start-radius: var-get($theme, 'box-border-radius');
            };
        }
    }
    %form-group-bundle-end--bootstrap {
        grid-area: 1 / 3;

        border: {
            inline: {
                start-width: 0;
                end-width: rem(1px);
            };
            block: {
                start-width: rem(1px);
                end-width: rem(1px);
            };
            start: {
                end-radius: var-get($theme, 'box-border-radius');
            };
            end: {
                end-radius: var-get($theme, 'box-border-radius');
            };
        }
    }

    // Label
    %bootstrap-label {
        position: static;
        display: block;
        color: var-get($theme, 'idle-secondary-color');
        padding: 0;
        line-height: rem(24px);
        transform: translateY(0);
        transform-origin: top left;
        margin-block-start: 0 !important;
        margin-block-end: rem(4px);
        height: auto;
        font-size: rem(16px);

        [dir='rtl'] & {
            transform-origin: top right;
        }
    }

    // Native Input
    %bootstrap-input {
        height: auto;
        line-height: 1.5;
        margin: 0;
        font-size: pad(
            map.get($bootstrap-font-size, 'compact'),
            map.get($bootstrap-font-size, 'cosy'),
            map.get($bootstrap-font-size, 'comfortable')
        );
        padding-block: pad-inline(
            map.get($bootstrap-block-padding, 'compact'),
            map.get($bootstrap-block-padding, 'cosy'),
            map.get($bootstrap-block-padding, 'comfortable')
        );
        padding-inline: pad-inline(
            map.get($bootstrap-inline-padding, 'compact'),
            map.get($bootstrap-inline-padding, 'cosy'),
            map.get($bootstrap-inline-padding, 'comfortable')
        );
        transition: box-shadow .15s ease-out, border .15s ease-out;
    }

    %bootstrap-input,
    %bootstrap-file-input {
        border: rem(1px) solid var-get($theme, 'border-color');
        padding-block: pad-inline(
            map.get($bootstrap-block-padding, 'compact'),
            map.get($bootstrap-block-padding, 'cosy'),
            map.get($bootstrap-block-padding, 'comfortable')
        );
        padding-inline: pad-inline(
            map.get($bootstrap-inline-padding, 'compact'),
            map.get($bootstrap-inline-padding, 'cosy'),
            map.get($bootstrap-inline-padding, 'comfortable')
        );
        grid-area: 1 / 2 / auto / auto;
        border-radius: var-get($theme, 'box-border-radius');
        z-index: 1;
    }

    %bootstrap-file-input {
        height: inherit;
    }

    %bootstrap-input--focus {
        border: rem(1px) solid var-get($theme, 'focused-border-color');
        box-shadow: 0 0 0 rem(4px) var-get($theme, 'focused-secondary-color');
    }

    %bootstrap-input--success {
        border: rem(1px) solid var-get($theme, 'success-secondary-color');
        box-shadow: 0 0 0 rem(4px) var-get($theme, 'success-shadow-color');
    }

    %bootstrap-input--error {
        border: rem(1px) solid var-get($theme, 'error-secondary-color');
        box-shadow: 0 0 0 rem(4px) var-get($theme, 'error-shadow-color');
    }

    %bootstrap-input--disabled {
        background: var-get($theme, 'border-disabled-background');
        border: rem(1px) solid var-get($theme, 'disabled-border-color');
        box-shadow: none;
    }

    %bootstrap-input--search {
        transform: translateY(0);
    }

    %form-group-prefix--disabled-bootstrap,
    %form-group-suffix--disabled-bootstrap {
        background: var-get($theme, 'border-disabled-background');
        border-color: var-get($theme, 'disabled-border-color');
        color: var-get($theme, 'disabled-text-color');
    }

    // Upload button for file type input
    %bootstrap-upload-button {
        padding: 0;

        [igxButton] {
            min-height: 0;
            height: calc(100% - #{rem(8px)});
        }
    }
}

/// Adds typography styles for the igx-input-group component.
/// Uses the 'subtitle-1', 'caption'
/// category from the typographic scale.
/// @group typography
/// @param {Map} $categories [(helper-text: 'caption', input-text: 'subtitle-1')] - The categories from the typographic scale used for type styles.
@mixin input-group-typography(
    $categories: (
        helper-text: 'caption',
        input-text: 'subtitle-1'
    )
) {
    $helper-text: map.get($categories, 'helper-text');
    $input-text: map.get($categories, 'input-text');

    %form-group-input {
        @include type-style($input-text) {
            margin: 0;
        }
    }

    %form-group-helper {
        @include type-style($helper-text) {
            margin: 0;
        }
    }

    %form-group-prefix,
    %form-group-suffix {
        &:not(igx-icon) {
            @include type-style($input-text) {
                margin: 0;
            }
        }
    }
}
