.small {
  --ig-size: 1.75rem;

  min-height: 1.75rem;
}

.medium {
  --ig-size: 2rem;

  min-height: 2rem;
}

.large {
  --ig-size: 3rem;

  min-height: 3rem;
}
