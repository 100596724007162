igx-input-group {
  &.igx-input-group {
    &--border,
    &--fluent {
      .igx-input-group {
        &__bundle {
          border: 0.0625rem solid var(--border-color);

          .igx-input-group__filler {
            border-color: transparent;
          }

          &-start {
            --border-color: transparent;
            --focused-border-color: transparent;
            --disabled-border-color: transparent;
            --error-secondary-color: transparent;
            --success-secondary-color: transparent;
            --warning-secondary-color: transparent;
          }

          &-end {
            --border-color: transparent;
            --focused-border-color: transparent;
            --disabled-border-color: transparent;
            --error-secondary-color: transparent;
            --success-secondary-color: transparent;
            --warning-secondary-color: transparent;
          }
        }

        &__notch {
          border-block-end-color: transparent !important;
          border-block-start-color: transparent !important;
        }
      }
    }

    &--focused {
      .igx-input-group__bundle {
        border: 0.0625rem solid var(--focused-border-color);
      }
    }

    &--disabled {
      .igx-input-group__bundle {
        border: 0.0625rem solid var(--disabled-border-color);
      }
    }

    &--cosy {
      &:not(.supy-textarea) {
        .igx-input-group {
          &__bundle {
            height: 2.75rem;

            &-main {
              padding: 0 !important;
            }

            .igx-input-group__filler {
              border-color: transparent;
            }

            &-start {
              border-width: 0;
            }

            &-end {
              border-color: transparent;
            }
          }

          &__input {
            font-size: 0.875rem;
            transform: none;
          }
        }
      }
    }
  }
}
