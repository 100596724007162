@use '@infragistics/igniteui-angular/theming' as igx;
@use 'sass:map';

@forward '@infragistics/igniteui-angular/theming';

$primary-a: (
  name: 'primary',
  default: hsl(var(--ig-primary-700)),
  hover: hsl(var(--ig-primary-400)),
  active: hsl(var(--ig-primary-400)),
  disabled: hsl(var(--ig-gray-500)),
);

$breadcrumb-a: (
  name: 'breadcrumb',
  default: hsl(var(--ig-gray-500)),
  hover: var(--ig-primary-300-contrast),
  active: var(--ig-primary-300-contrast),
  disabled: hsl(var(--ig-gray-500)),
);

$colors: ($primary-a, $breadcrumb-a);

@mixin anchor-colors($palettes...) {
  @each $palette in $palettes {
    $name: map.get($palette, 'name');

    .supy-anchor {
      &--#{$name} {
        color: map.get($palette, 'default');

        &:hover,
        &.supy-anchor--hover {
          color: map.get($palette, 'hover');
        }

        &:active,
        &.supy-anchor--active {
          color: map.get($palette, 'active');
        }

        &:disabled,
        &.supy-anchor--disabled {
          color: map.get($palette, 'disabled');
        }
      }
    }
  }
}

%supy-anchor {
  @include igx.type-style($category: 'body-1') {
    cursor: pointer;
    text-decoration: none;
  }
}

.supy-anchor {
  @extend %supy-anchor;

  &--underlined {
    @include igx.type-style($category: 'body-1') {
      text-decoration: underline;
    }
  }
}

a {
  @extend %supy-anchor;
}

@include anchor-colors($colors...);
