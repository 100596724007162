$alignment-type: (
  flex-start: flex-start,
  center: center,
  flex-end: flex-end,
  stretch: stretch,
  baseline: baseline,
);

$justification-type: (
  flex-start: flex-start,
  center: center,
  flex-end: flex-end,
  stretch: stretch,
  space-evenly: space-evenly,
  space-around: space-around,
  space-between: space-between,
);

$flex-direction: (row, row-reverse, column, column-reverse);
$flex-wrap: (wrap, nowrap, wrap-reverse);
$flex-shrink-grow: (0, 1);

$flex-small-gap: 0.5rem;
$flex-medium-gap: 1rem;
$flex-large-gap: 2rem;

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-auto {
  flex: auto;
}

@each $value in $flex-shrink-grow {
  .flex-grow-#{$value} {
    flex-grow: $value;
  }
  .flex-shrink-#{$value} {
    flex-shrink: $value;
  }
}

@each $value in $flex-wrap {
  .flex-#{$value} {
    flex-wrap: $value;
  }
}

@each $value in $flex-direction {
  .flex-#{$value} {
    flex-direction: $value;
  }
}

@each $value, $suffix in $alignment-type {
  .align-self-#{$suffix} {
    align-self: $value;
  }
}

@each $value, $suffix in $alignment-type {
  .align-items-#{$suffix} {
    align-items: $value;
  }
}

@each $value, $suffix in $alignment-type {
  .align-content-#{$suffix} {
    align-content: $value;
  }
}

@each $value, $suffix in $justification-type {
  .justify-content-#{$suffix} {
    justify-content: $value;
  }
}

@mixin flex-gap {
  .flex-gap {
    &-sm {
      gap: $flex-small-gap;
    }

    &-md {
      gap: $flex-medium-gap;
    }

    &-lg {
      gap: $flex-large-gap;
    }
  }
}

@include flex-gap();
