.supy-input--compact {
  --ig-size: 2rem;

  &:not(.supy-textarea) {
    .igx-input-group {
      &__bundle {
        height: 2rem !important;

        &-main {
          padding: 0 !important;
        }
      }

      &__input {
        font-size: 0.875rem;
        transform: none;
      }
    }
  }
}
