@mixin override() {
  .cdk-overlay-container {
    // increase z-index to be on top of igx overlay
    // TODO: remove once igx overlays are removed

    z-index: 10005;
  }

  .mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: hsla(var(--ig-gray-900), 0.8);
    --mdc-plain-tooltip-supporting-text-color: var(--ig-primary-500-contrast);
    --mdc-plain-tooltip-container-shape: 0.25rem;
  }
}
