@use '@infragistics/igniteui-angular/theming' as igx;

@forward '@infragistics/igniteui-angular/theming';

$h1-style: igx.type-style(
  $font-size: 2.75rem,
  $font-weight: 500,
  $line-height: 3rem,
  $letter-spacing: -0.03125rem,
  $margin-block-start: 0,
);

$h2-style: igx.type-style(
  $font-size: 2rem,
  $font-weight: 500,
  $line-height: 2.5rem,
  $letter-spacing: -0.03125rem,
);

$h3-style: igx.type-style(
  $font-size: 1.5rem,
  $font-weight: 500,
  $line-height: 2rem,
  $letter-spacing: -0.03125rem,
);

$h4-style: igx.type-style(
  $font-size: 1.25rem,
  $font-weight: 500,
  $line-height: 1.375rem,
  $letter-spacing: -0.03125rem,
);

$h5-style: igx.type-style(
  $font-size: 1rem,
  $font-weight: 600,
  $line-height: 1.5rem,
  $letter-spacing: -0.03125rem,
);

$h6-style: igx.type-style(
  $font-size: 0.875rem,
  $font-weight: 600,
  $line-height: 1.5rem,
  $letter-spacing: -0.03125rem,
);

$subtitle-1-style: igx.type-style(
  $font-size: 1rem,
  $font-weight: 300,
  $line-height: 1.25rem,
  $letter-spacing: 0,
);

$body-1-style: igx.type-style(
  $font-size: 1rem,
  $font-weight: 400,
  $line-height: 1.5rem,
  $letter-spacing: -0.015625rem,
  $margin-block-start: 0,
  $margin-block-end: 0,
);

$body-2-style: igx.type-style(
  $font-size: 0.875rem,
  $font-weight: 400,
  $line-height: 1.25rem,
  $letter-spacing: -0.015625rem,
);

$subtitle-2-style: igx.type-style(
  $font-size: 0.875rem,
  $font-weight: 300,
  $line-height: 1rem,
  $letter-spacing: 0,
);

$button-style: igx.type-style(
  $font-size: 0.875rem !important,
  $font-weight: 400,
  $line-height: 1rem,
  $letter-spacing: 0,
  $text-transform: initial,
);

$caption-style: igx.type-style(
  $font-size: 0.75rem,
  $font-weight: 400,
  $line-height: 1rem,
  $letter-spacing: 0,
);

$overline-style: igx.type-style(
  $font-size: 0.675rem,
  $font-weight: 300,
  $line-height: 0.75rem,
  $letter-spacing: 0,
  $text-transform: uppercase,
);

$material-type-scale: type-scale(
  $h1: $h1-style,
  $h2: $h2-style,
  $h3: $h3-style,
  $h4: $h4-style,
  $h5: $h5-style,
  $h6: $h6-style,
  $subtitle-1: $subtitle-1-style,
  $subtitle-2: $subtitle-2-style,
  $body-1: $body-1-style,
  $body-2: $body-2-style,
  $button: $button-style,
  $caption: $caption-style,
  $overline: $overline-style,
);

@include igx.typography($font-family: $default-font-family, $type-scale: igx.$material-type-scale);

// TODO: refactor this to correct file
/* stylelint-disable unit-allowed-list */
@media only screen and (min-width: 1920px) {
  :root {
    --ig-base-font-size: #{$large-font-size};

    font-size: $large-font-size;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  :root {
    --ig-base-font-size: #{$default-font-size};

    font-size: $default-font-size;
  }
}

@media only screen and (max-width: 1366px) {
  :root {
    --ig-base-font-size: #{$small-font-size};

    font-size: $small-font-size;
  }
}

/* stylelint-enable unit-allowed-list */
