@mixin scrollbar(
  $track-color,
  $track-border-radius,
  $thumb-color,
  $thumb-hover-color,
  $thumb-border-radius,
  $scrollbar-width
) {
  /* width */
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: $track-border-radius;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: $thumb-border-radius;

    /* Handle on hover */
    &:hover {
      background: $thumb-hover-color;
    }
  }
}
